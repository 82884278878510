import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="container">
      <div className="row">
        <div className="col s12">
          <h1>Contact</h1>
        </div>
        <div className="col m3 hide-on-small-only"></div>
        <form
          className="col s12 m6"
          name="contact"
          method="POST"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="row">
            <div className="col s12">
              <div className="input-field">
                <input type="text" name="name" />
                <label>Name</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12">
              <div className="input-field">
                <input type="email" name="email" />
                <label>Email</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12">
              <div className="input-field">
                <textarea
                  name="message"
                  className="materialize-textarea"
                ></textarea>
                <label>Message</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12 submit-btn">
              <button type="submit">Send</button>
            </div>
          </div>
          <p className="main-content tagline center-align">
            No unsolicited submissions.
          </p>
          <p className="main-content tagline center-align">
          Imprint Entertainment will not directly contact you unsolicited. If you believe you've been contacted directly by Imprint Entertainment by phone or email, we suggest you do not respond.
          </p>
        </form>
        <div className="col m3 hide-on-small-only"></div>
      </div>
    </div>
  </Layout>
)

export default Contact
